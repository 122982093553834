import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { NotificationContainer, NotificationManager, } from "react-notifications";
import DashboardHeader from './DashboardHeader';



const AcademicInformation = () => {
  const auth = JSON.parse(localStorage.getItem("auth_user_info"));
  const applicantID = auth ? auth.id : null;
  const [rows, setRows] = useState([
    {
      applicantId: applicantID,
      id: null,
      jobTitle: null,
      nameOfEmployer: null,
      organizationName: null,
      present: false,
      typeOfJob: null,
      yearEnd: null,
      yearStart: null
    }
  ]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
 console.log("token",token)
 const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer





  // Fetches data from the server and sets it to rows.
  useEffect(() => {
    const fetchData = async () => {
 

// Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${token}` // JWT token of the logged-in user
  }
})
  .then(response => response.json())
  .then(campaignResponse => {
    const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
    setLoading(false);

    if (!latestCampaign) {
      console.error('No campaign found');
      return; // If no campaign exists, handle appropriately
    }

    console.log('Latest campaign:', latestCampaign);

    // Fetch professional details related to both the user and the latest campaign
    fetch(`${process.env.REACT_APP_BASE_URL_strapi}/professional-details?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}` // JWT token of the logged-in user
      }
    })
      .then(response => response.json())
      .then(professional_details => {
        console.log('User with professional-details:', professional_details);

        const professionalDetails = professional_details.data; // Extract professional details
        console.log('Professional-details related to the user and latest campaign:', professionalDetails);
        
        if (professionalDetails && professionalDetails.length > 0) {
          const transformedRows = professionalDetails.map((detail) => ({
            present: detail.attributes.on_going,
            typeOfJob: detail.attributes.type_of_job,
            yearEnd: detail.attributes.end_year,
            jobTitle: detail.attributes.job_title,
            organizationName: detail.attributes.organization_name,
            yearStart: detail.attributes.start_year,
            id:detail.id

          }));
          setRows(transformedRows); // Set the rows with professional details if found
        } else {
          console.log('No professional details related to the latest campaign found for the user');
        }
      })
      .catch(error => {
        console.error('Error fetching user and professional details:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching the latest campaign:', error);
  });
    };

    fetchData();
  }, []);




  // Data Posting Model
  const handleAddMore = () => {
    setRows([...rows, {
      applicantId: applicantID,
      id: null,
      jobTitle: null,
      // nameOfEmployer: null,
      organizationName: null,
      present: false,
      typeOfJob: null,
      yearEnd: null,
      yearStart: null
    }]);
    // console.log(rows);
  };

  const handleRemove = async (index, ) => {
    console.log("index", index);
    console.log("index", rows[index].id);
console.log("rows",rows)
    // Call the delete API for the Strapi Academic collection
    try {
      const token = localStorage.getItem('token'); // Assuming you are storing the JWT token in localStorage
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL_strapi}/professional-details/${rows[index].id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Provide the token for authentication
          },
        }
      );
  
      if (response.status === 200) {
        // Successfully deleted from the backend, now remove from the frontend
        setRows(rows.filter((row, i) => i !== index));
        console.log("Item successfully deleted from both front-end and backend");
      } else {
        console.error("Failed to delete item from backend", response);
      }
    } catch (error) {
      console.error("Error deleting the item from Strapi", error);
    }
  };

  const keyToName = {
    jobTitle: 'Job Title',
    // nameOfEmployer: 'Name of Employer',
    organizationName: 'Type of Job',
    typeOfJob: 'Organization Name',
    yearStart: 'Start Year'
  }

  // const handleInputChange = (index, field, value) => {
  //   setRows(rows.map((row, i) => {
  //     if (i !== index) return row;
  //     return { ...row, [field]: field === 'yearStart' || field === 'yearEnd' ? parseInt(value) : value };
  //   }));
  // };

  const handleInputChange = (index, field, value) => {
    setRows(rows.map((row, i) => {
      if (i !== index) return row;
      let newRow = { ...row, [field]: field === 'yearStart' || field === 'yearEnd' ? parseInt(value) : value };
      if (field === 'yearStart') {
        newRow.yearEnd = newRow.yearEnd >= value ? newRow.yearEnd : 0;
      } else if (field === 'yearEnd') {
        newRow.yearStart = newRow.yearStart <= value ? newRow.yearStart : 0;
      }
      return newRow;
    }));
  };

  const validateRow = (row) => {
    const rowErrors = {};
  
    if (!row.jobTitle) rowErrors.jobTitle = 'Job Title is required';
    if (!row.organizationName) rowErrors.organizationName = 'Organization Name is required';
    if (!row.typeOfJob) rowErrors.typeOfJob = 'Type of Job is required';
    if (!row.yearStart) rowErrors.yearStart = 'Start Year is required';
    if (!row.present && !row.yearEnd) rowErrors.yearEnd = 'End Year is required if the job is not ongoing';
  
    return rowErrors;
  };
  const validateForm = () => {
    const newErrors = rows.map(validateRow);
    setErrors(newErrors);
  
    // Check if there are any errors
    return newErrors.every((rowError) => Object.keys(rowError).length === 0);
  };
  // Post Request
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("rows",rows)
    if (!validateForm()) {
      return; // Stop if validation fails
    }
    const professionalinformation = rows.map((row, index) => ({
      // applicantId: applicantID,
      job_title: row.jobTitle,
      organization_name: row.organizationName,
      type_of_job: row.typeOfJob,
      end_year: parseInt(row.yearEnd),
      start_year: parseInt(row.yearStart),
      on_going: row.present,
      id: row.id || null // Check if the row has an ID for updating

    }));


    try {
      // Iterate through each row and decide whether to create or update
      for (let i = 0; i < professionalinformation.length; i++) {
        const currentRow = professionalinformation[i];
         console.log("currentRow",currentRow)
        if (currentRow.id) {
          // If the row has an ID, it's an update (PUT request)
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/professional-details/${currentRow.id}`, {
            method: 'PUT', // Update existing record
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: currentRow }) // Send the updated data
          });
          
          if (!response.ok) {
            throw new Error('Failed to update academic detail');
          }
  
          const updatedData = await response.json();
          console.log('Updated Data:', updatedData);
  
        } else {
          delete currentRow.id;

          // If no ID, it's a create (POST request)
          const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/professional-details`, {
            method: 'POST', // Create new record
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: {...currentRow,applicationId },}) // Send the new data
            
          });
  
          if (!response.ok) {
            throw new Error('Failed to create academic detail');
          }
  
          const createdData = await response.json();
          console.log('Created Data:', createdData);
          localStorage.setItem("professional_detail", createdData.id);
        }
      }
  
      // Navigate after successful submit
      navigate("/application/resume");
  
    } catch (error) {
      console.error('Error:', error);
    }

  };

  // Year Array
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1998 }, (v, i) => ({ id: i + 1, year: (1999 + i).toString() }));


  return (
    <div>
      {/* Header Start */}
      <div className="mb-5">
        <DashboardHeader />
      </div>
      <div className="container row justify-content-center m-auto mb-5">
        <div className="col-md-10">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-4">
              <Navbar />
            </div>
            <div className="col-sm-12 col-md-8 mb-4">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>

              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-body">
                      <h4>Professional Details</h4>
                      <p className='my-3'>Please proceed by providing your most recent professional information.</p>
                      <div className="row">
                        {rows && rows.map((row, index) => (
                          <React.Fragment key={index}>
                            {/* <h5>{row.jobTitle || 'Please proceed by providing your most recent professional information.'}</h5> */}
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`jobTitle${index}`} className="">
                                Job Title <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`jobTitle${index}`}
                                placeholder="Enter Job Title"
                                value={row.jobTitle || ''}
                                onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)}
                              />
                                {errors[index]?.jobTitle && <p className="text-danger">{errors[index].jobTitle}</p>}

                            </div>

                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`organizationName${index}`} className="">
                                Organization Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`organizationName${index}`}
                                placeholder="Enter Organization Name"
                                value={row.organizationName || ''}
                                onChange={(e) => handleInputChange(index, 'organizationName', e.target.value)}
                              />
                                  {errors[index]?.organizationName && <p className="text-danger">{errors[index].organizationName}</p>}

                            </div>

                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`typeOfJob${index}`}>Type of Job <span className="text-danger">*</span></label>
                              <select
                                id={`typeOfJob${index}`}
                                className="form-control"
                                name="typeOfJob"
                                value={row.typeOfJob || ''}
                                onChange={(e) => handleInputChange(index, 'typeOfJob', e.target.value)}
                              >
                                <option value="">Please select job type</option>
                                <option value="Internship">Internship</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Full Time">Full Time</option>
                              </select>
                              {errors[index]?.typeOfJob && <p className="text-danger">{errors[index].typeOfJob}</p>}
                   
                            </div>
                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`yearStart${index}`}>Start Year <span className="text-danger">*</span></label>
                              {/* <pre>{row.yearStart.toString()}</pre> */}
                              <select
                                id={`yearStart${index}`}
                                className="form-control"
                                name="yearStart"
                                value={row.yearStart || 0}
                                onChange={(e) => handleInputChange(index, 'yearStart', e.target.value)}
                              >
                                {row.yearStart === null || row.yearStart === 0 ? <option key={0} value={0}>Select Start Year</option> : null}

                                {years.map((item) => (
                                  <option key={item.id} value={item.year}>
                                    {item.year}
                                  </option>
                                ))}
                              </select>          
                                {errors[index]?.yearStart && <p className="text-danger">{errors[index].yearStart}</p>}

                            </div>

                            <div className="form-group mb-3 col-sm-12 col-md-4">

                              {row.present ? null : (<span>
                                <label htmlFor={`yearEnd${index}`}>End Year</label>
                                <select
                                  id={`yearEnd${index}`}
                                  className="form-control"
                                  name="yearEnd"
                                  value={row.yearEnd || 0}
                                  onChange={(e) => handleInputChange(index, 'yearEnd', e.target.value)}
                                >

                                  {row.yearEnd === null || row.yearEnd === 0 ? <option key={0} value={0}>Select End Year</option> : null}
                                  {years.filter((year) => year.year >= row.yearStart).map((year) => (
                                    <option key={year.id} value={year.year}>
                                      {year.year}
                                    </option>
                                  ))}
                                </select>
                                {errors[index]?.yearEnd && <p className="text-danger">{errors[index].yearEnd}</p>}

                              </span>


                              )}
                            </div>

                            <div className="form-group mb-3 col-sm-12 col-md-4">
                              <div className="ul-widget-app__task-list mt-4 pt-2">
                                <label className="checkbox checkbox-primary ">
                                  <input type="checkbox" name="present" checked={row.present || false} onChange={(e) => handleInputChange(index, 'present', e.target.checked)} />
                                  <strong>
                                    On-going
                                  </strong>
                                  <span className="checkmark"></span>
                                </label>
                                {errors[index]?.present && <p className="text-danger">{errors[index].present}</p>}

                              </div>
                              {/* <div className="mt-3">
                                <input
                                  type="checkbox"
                                  id={`presentcheck${index}`}
                                  name="present"
                                  className="mx-1  mt-4"
                                  checked={row.present || false}
                                  onChange={(e) => handleInputChange(index, 'present', e.target.checked)}
                                />
                                <label htmlFor={`presentcheck${index}`} className="d-inline-block mt-4">On-going</label>

                              </div> */}

                            </div>

                            {/* <div className="form-group mb-3 col-sm-12 col-md-4">
                              <label htmlFor={`nameOfEmployer${index}`} className="">
                                Name of Employer <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`nameOfEmployer${index}`}
                                placeholder="Name of Employer"
                                value={row.nameOfEmployer || ''}
                                onChange={(e) => handleInputChange(index, 'nameOfEmployer', e.target.value)}
                              />
                            </div> */}
                            <div className="form-group mb-4 col-12">
                              {rows.length > 1 && (
                                <button type="button" className="btn btn-danger text-white mt-4" onClick={() => handleRemove(index)}>
                                  Remove
                                </button>
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="text-left">
                        <button type="button" className="btn btn-secondary" onClick={handleAddMore}>
                          Add More
                        </button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="mc-footer">
                        <div className="row text-center">
                          <div className="col-lg-12">
                            <button type="submit" className="btn btn-primary text-white m-1">
                              Save & Next
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary m-1"
                              onClick={() => navigate('/application/resume')}
                            >
                              Skip
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}


            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default AcademicInformation;

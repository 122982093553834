import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardHeader from './DashboardHeader';
import { NotificationContainer, NotificationManager, } from "react-notifications";

const Resume = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const [inputKey, setInputKey] = useState(Date.now());
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(true);
    const [resumeId, setResumeId] = useState(null); // Holds the resume ID if it exists
    const [errorMessage, setErrorMessage] = useState('');

    const auth = JSON.parse(localStorage.getItem("auth_user_info"));
    const applicantID = auth ? auth.id : null;
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const applicationId = parseInt(localStorage.getItem('id'), 10); // Convert to integer

    // useEffect(() => {
    //     console.log(file);
    // }, [file]);

    useEffect(() => {
   

      // Fetch the latest campaign first
fetch(`${process.env.REACT_APP_BASE_URL_strapi}/campaigns?sort=createdAt:desc&pagination[limit]=1`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}` // JWT token of the logged-in user
    }
  })
    .then(response => response.json())
    .then(campaignResponse => {
      setLoading(false);

      const latestCampaign = campaignResponse.data[0]; // Get the latest campaign
  
      if (!latestCampaign) {
        console.error('No campaign found');
        return; // Handle if no campaign exists
      }
  
      console.log('Latest campaign:', latestCampaign);
  
      // Fetch resumes related to both the user and the latest campaign
      fetch(`${process.env.REACT_APP_BASE_URL_strapi}/resumes?filters[campaign][id][$eq]=${latestCampaign.id}&filters[application][id][$eq]=${applicationId}&populate=resume `, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}` // JWT token of the logged-in user
        }
      })
        .then(response => response.json())
        .then(resume => {
          console.log('User with resumes:', resume);
  
          const resumes = resume.data; // Extract resumes
          console.log('User with resumes:', resumes);

          if (resumes.length > 0 && resumes[0].attributes.resume?.data?.attributes) {
            console.log('Resume URL:', resumes[0].attributes.resume.data.attributes.url);
          
            setFormData({
              path: resumes[0].attributes.resume.data.attributes.url, // URL of the existing resume
              name: resumes[0].attributes.resume.data.attributes.name, // Name of the resume file
            });
          
            setResumeId(resumes[0].id); // Save the resume ID for updating later
          } else {
            console.log('No resumes related to the latest campaign found for the user');
          }
        })
        .catch(error => {
          console.error('Error fetching user resumes:', error);
        });
    })
    .catch(error => {
      console.error('Error fetching the latest campaign:', error);
    });
    }, []);

    const handleFileChange = (e) => {
        // console.log("I am working")
        if (e.target.files.length > 0) {
            if (e.target.files[0].size <= 5000000 && e.target.files[0].type === 'application/pdf') {
                setFile(e.target.files[0]);
            } else {
                NotificationManager.error("File must be a PDF and less than 5MB");
                setInputKey(Date.now()); // Reset the file input field
            }
        } else {
            NotificationManager.error('No file selected');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        setErrorMessage('');

        // If no file is uploaded, set an error message and return
        if (!file) {
          setErrorMessage('Please upload a resume before submitting.');
          return;
        }
      
        try {
          // If a resume already exists (i.e., resumeId is set), delete it first
          if (resumeId) {
            const deleteResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/resumes/${resumeId}`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
      
            if (!deleteResponse.ok) {
              throw new Error('Failed to delete existing resume');
            }
      
            console.log('Existing resume deleted successfully');
          }
      
          // Proceed to upload the new file
          const formData = new FormData();
          formData.append('file', file); // Append the uploaded file to the form data
          formData.append('application_id', applicationId); // Append the application_id to the form data

          const createResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/resumes`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
      
          if (!createResponse.ok) {
            throw new Error('Failed to upload resume');
          }
      
          const fileData = await createResponse.json();
          console.log('New resume uploaded successfully:', fileData);
          localStorage.setItem("resume", fileData.id);

          // After successful upload, reset the input
          setInputKey(Date.now());
          // Optionally, update the state with the new resume path
          setFormData({
            path: fileData[0].url, // Set the new URL of the uploaded resume
            name: fileData[0].name,
          });
          setResumeId(fileData[0].id); // Set the new resumeId after creating a new resume

      
        } catch (error) {
          console.error('Error handling resume upload:', error);
        }
        navigate("/application/personal-statement");

      };

    return (
        <div>
            {/* Header Start */}
            <div className="mb-5">
                <DashboardHeader />
            </div>

            <div className="container row justify-content-center m-auto mb-5">
                <div className="col-md-10">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4">
                            <Navbar />
                        </div>

                        <div className="col-sm-12 col-md-8 mb-4">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                                </div>

                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Resume</h4>
                                            <div className="row">

                                                <label htmlFor="file">Upload Resume  <span className="text-danger">*</span></label>
                                                <div className="input-group mb-3 col-12">
                                                    <input type="file" className="form-control" id="file" onChange={handleFileChange} key={inputKey} accept=".pdf" />
                                                    <label className="input-group-text" htmlFor="file">Upload</label>
                                                </div>
                                                {errorMessage && <p className="text-danger">{errorMessage}</p>}

                                                <div className="input-group mb-3 col-12">
                                                    {formData && <button type="button" className="btn btn-outline-primary m-1" onClick={() => window.open(`${formData.path}`, '_blank')}>View</button>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="mc-footer">
                                                <div className="row text-center">
                                                    <div className="col-lg-12">
                                                        <button type="submit" className="btn btn-primary text-white m-1">Save & Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>

    )

};

export default Resume;
